<template lang="pug">
.tickets
  app-ticket(v-for="(ticket, index) in tickets" :ticket="ticket" :key="index")
</template>

<script>
import { onMounted, ref } from "vue";
import { axios } from "@/utils/axios";
import AppTicket from "@/components/Tickets/AppTicket";

import { store } from "@/store";

export default {
  components: { AppTicket },
  setup() {
    const tickets = ref([]);

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        const { data } = await axios.get("tickets/");
        tickets.value = data.payload;
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    return { tickets };
  },
};
</script>

<style lang="scss" scoped>
.tickets {
  @apply grid;
  @apply grid-cols-1;
  @apply gap-4;
  @screen sm {
    @apply grid-cols-2;
  }
  @screen md {
    @apply grid-cols-2;
  }
  @screen xl {
    @apply grid-cols-3;
  }
}
</style>
